var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('CForm',{ref:"form",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{staticClass:"is-invalid",attrs:{"label":"ຫົວຂໍ້ແຜນງານ :","prop":"title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ວັນເລີ່ມ :")]),_c('date-picker',{attrs:{"config":_vm.options,"prop":"start"},model:{value:(_vm.form.start),callback:function ($$v) {_vm.$set(_vm.form, "start", $$v)},expression:"form.start"}})],1)])],1),_c('CCol',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ວັນສິ້ນສຸດ :")]),_c('date-picker',{attrs:{"config":_vm.options,"prop":"end"},model:{value:(_vm.form.end),callback:function ($$v) {_vm.$set(_vm.form, "end", $$v)},expression:"form.end"}})],1)])],1),_c('CCol',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vueEditor',{staticClass:"is-invalid",attrs:{"prop":"description","rows":"10"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("ສະຖານະແຜນງານ :")]),_c('v-select',{staticClass:"is-invalid",attrs:{"prop":"status","options":_vm.statusList},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('span',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"label":"ຄວາມຄືບໜ້າຂອງແຜນງານ(%) :","prop":"complete"},model:{value:(_vm.form.complete),callback:function ($$v) {_vm.$set(_vm.form, "complete", $$v)},expression:"form.complete"}})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CButton',{staticClass:"float-right",attrs:{"disabled":invalid,"type":"submit","variant":"outline","color":"warning"}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v("ບັນທຶກ ")],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }