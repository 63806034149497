<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>ຂໍ້ມູນແຜນງານ</strong>
        <CButton @click="modalCreate = true" variant="outline" color="primary" class="float-right">
          <CIcon name="cil-plus" />ເພີ້ມແຜນງານ
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12">
            <CDataTable
              :items="items"
              :fields="fields"
              :loading="loading"
              hover
              sorter
              :table-filter="{placeholder: 'ຄົ້ນຫາ...', label: ' ', align: 'right'}"
              :items-per-page="20"
              pagination
            >
              <template #status="{item}">
                <td>
                  <CBadge :color="getBadge(item.status)">
                    <strong>{{item.status}}</strong>
                  </CBadge>
                </td>
              </template>
              <template #actions="row">
                <td>
                  <CDropdown
                    size="sm"
                    toggler-text="ຈັດການ"
                    color="dark"
                    class="m-0 d-inline-block"
                  >
                    <CDropdownItem v-on:click="onClickUpdate(row.item)">ແກ້ໄຂ</CDropdownItem>
                    <CDropdownItem :disabled="userGroup != 'administrator'" v-on:click="onClickDelete(row.item)">ລົບ</CDropdownItem>
                  </CDropdown>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <!-- Modal Create New Branch -->
    <CModal
      title="ຟອມເພີ້ມຂໍ້ມຸນແຜນງານ"
      size="lg"
      :show.sync="modalCreate"
      :closeOnBackdrop="false"
    >
      <activityFormCreate @on_success="onSubmitCreate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
    <!-- Modal Update Branch -->
    <CModal
      title="ຟອມແກ້ໄຂຂໍ້ມູນແຜນງານ"
      size="lg"
      :show.sync="modalUpdate"
      :closeOnBackdrop="false"
    >
      <activityFormUpdate :activity="activityData" @on_success="onSubmitUpdate" />
      <template #footer>
        <div></div>
      </template>
    </CModal>
  </div>
</template>
<script>
import {
  getActivityAPI,
  getActivityByIdAPI,
  createActivityAPI,
  updateActivityAPI,
  deleteActivityAPI,
} from "@/api/activity";
import activityFormCreate from "./components/ActivityFormCreate";
import activityFormUpdate from "./components/ActivityFormUpdate";
import { decrypt } from "@/libs/crypto";
export default {
  name: "Activity",
  components: {
    activityFormCreate,
    activityFormUpdate,
  },
  data() {
    return {
      userGroup: decrypt(this.$store.getters.getUserGroup),
      loading: true,
      modalCreate: false,
      modalUpdate: false,
      items: [],
      fields: [
        { key: "id", label: "ລະຫັດ" },
        { key: "title", label: "ຫົວຂໍ້" },
        { key: "start", label: "ວັນທີເວລາເລີ່ມ" },
        { key: "end", label: "ວັນທີ່ສິ້ນສຸດ" },
        { key: "createDate", label: "ວັນທີ່ສ້າງ" },
        { key: "createAt", label: "ຜູ້ສ້າງ" },
        { key: "status", label: "ສະຖານະ" },
        { key: "actions", label: "ຈັດການ" },
      ],
      activityData: {},
    };
  },
  mounted() {
    this.initialGetData();
  },
  methods: {
    getBadge(status) {
      return status === "ວາງແຜນງານ"
        ? "danger"
        : status === "ກໍາລັງດໍາເນີນການ"
        ? "warning"
        : status === "ສໍາເລັດແລ້ວ"
        ? "success"
        : "success";
    },
    // Load data
    initialGetData() {
      setTimeout(() => {
        this.loading = false;
      }, 5000);
      getActivityAPI().then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },

    // Create New
    onSubmitCreate(credentials) {
      createActivityAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalCreate = false;
    },

    // Click Update button
    onClickUpdate(item) {
      this.activityData = item;
      this.modalUpdate = true;
    },
    // function update
    onSubmitUpdate(credentials) {
      updateActivityAPI(credentials)
        .then((response) => {
          this.$awn.success(response.data.message);
          this.initialGetData();
        })
        .catch((error) => {
          this.$awn.alert(error.response.data.message);
        });
      this.modalUpdate = false;
    },

    // Click delete button
    onClickDelete(item) {
      let onOk = () => {
        deleteActivityAPI(item.id)
          .then((response) => {
            this.$awn.success(response.data.message);
            this.initialGetData();
          })
          .catch((error) => {
            this.$awn.alert(error.response.data.message);
          });
      };
      this.$awn.confirm("ເຈົ້າຕ້ອງການລົບຂໍ້ມູນນີ້ແທ້ ຫຼີ ບໍ່ ?", onOk);
    },
  },
};
</script>
