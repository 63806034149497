<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput class="is-invalid" label="ຫົວຂໍ້ແຜນງານ :" prop="title" v-model="form.title" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required">
            <div class="form-group">
              <label>ວັນເລີ່ມ :</label>
              <date-picker :config="options" prop="start" v-model="form.start"></date-picker>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required">
            <div class="form-group">
              <label>ວັນສິ້ນສຸດ :</label>
              <date-picker :config="options" prop="end" v-model="form.end"></date-picker>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <vueEditor class="is-invalid" prop="description" v-model="form.description" rows="10" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput label="ສະຖານະແຜນງານ :" prop="status" v-model="form.status" disabled />
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="primary"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { VueEditor } from "vue2-editor";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ActivityFormCreate",
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor,
  },
  data() {
    return {
      form: {
        title: "",
        description: "",
        start: "",
        end: "",
        color: "#FF0000",
        textColor: "#FFFFFF",
        status: "ວາງແຜນງານ",
      },
      options: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("on_success", {
        title: this.form.title,
        description: this.form.description,
        start: this.form.start,
        end: this.form.end,
        color: this.form.color,
        textColor: this.form.textColor,
        status: this.form.status,
        createAt: decrypt(this.$store.getters.getFullName),
      });
      this.form.title = "";
      this.form.description = "";
      this.form.start = "";
      this.form.end = "";
    },
  },
};
</script>