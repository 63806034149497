<template>
  <ValidationObserver v-slot="{ invalid }">
    <CForm ref="form" :model="form" @submit.prevent="handleSubmit">
      <CRow>
        <CCol sm="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <CInput class="is-invalid" label="ຫົວຂໍ້ແຜນງານ :" prop="title" v-model="form.title" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required">
            <div class="form-group">
              <label>ວັນເລີ່ມ :</label>
              <date-picker :config="options" prop="start" v-model="form.start"></date-picker>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <validation-provider rules="required">
            <div class="form-group">
              <label>ວັນສິ້ນສຸດ :</label>
              <date-picker :config="options" prop="end" v-model="form.end"></date-picker>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="12">
          <validation-provider rules="required" v-slot="{ errors }">
            <vueEditor class="is-invalid" prop="description" v-model="form.description" rows="10" />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </validation-provider>
        </CCol>
        
        <CCol sm="6">
          <validation-provider rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label>ສະຖານະແຜນງານ :</label>
              <v-select
                class="is-invalid"
                prop="status"
                v-model="form.status"
                :options="statusList"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </CCol>
        <CCol sm="6">
          <CInput
              label="ຄວາມຄືບໜ້າຂອງແຜນງານ(%) :"
              prop="complete"
              v-model="form.complete"
            />
        </CCol>
        <CCol sm="12">
          <CButton
            :disabled="invalid"
            type="submit"
            variant="outline"
            color="warning"
            class="float-right"
          >
            <CIcon name="cil-save" />ບັນທຶກ
          </CButton>
        </CCol>
      </CRow>
    </CForm>
  </ValidationObserver>
</template>
 <script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { VueEditor } from "vue2-editor";
import { decrypt } from "@/libs/crypto";
export default {
  name: "ActivityFormUpdate",
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor
  },
  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        title: this.activity.title,
        complete: this.activity.complete,
        description: this.activity.description,
        start: this.activity.start,
        end: this.activity.end,
        color: this.activity.color,
        textColor: this.activity.textColor,
        createAt: this.activity.createAt,
        status: this.activity.status,
        color: "",
        textColor: "",
        
      };
    },
  },
    data() {
    return {
      statusList: ["ວາງແຜນງານ","ກໍາລັງດໍາເນີນການ","ສໍາເລັດແລ້ວ"],
      options: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
      },
    };
  },
  methods: {
    handleSubmit() {
      if(this.form.status == "ກໍາລັງດໍາເນີນການ"){
        this.form.color = "#F08C00"
        this.form.textColor = "#FFFFFF"

      }else if(this.form.status == "ສໍາເລັດແລ້ວ") {
        this.form.color = "#1BBD93"
        this.form.textColor = "#FFFFFF"

      }else if(this.form.status == "ວາງແຜນງານ") {
        this.form.color = "#FF0000"
        this.form.textColor = "#FFFFFF"

      }
      this.$emit("on_success", {
        id: this.activity.id,
        title: this.form.title,
        complete: this.form.complete,
        description: this.form.description,
        start: this.form.start,
        end: this.form.end,
        color: this.form.color,
        textColor: this.form.textColor,
        status: this.form.status,
        status_by: decrypt(this.$store.getters.getFullName),
      });
    },
  },
};
</script>